import TagManager from '@sooro-io/react-gtm-module'
import { getContextValueBySku } from '../bh-list-context/utils/bh-tracking-context'

export const addToCart = ({
	product,
	selectedProduct,
	currency,
	qty,
	pageContext,
	pageId,
	widgetContext,
	getLocalStorageByGroup,
}) => {
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })

	const gtmEvent = 'add_to_cart'
	const listContext =
		getContextValueBySku({
			sku: product?.sku,
			pageContext,
			pageId,
			enablePageId: false,
			widgetContext,
			getLocalStorageByGroup,
		}) ?? gtmEvent

	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			ecommerce: {
				currency,
				value: product?.prices?.final ? product.prices.final * (qty ?? 1) : ' ',
				items: [
					{
						item_id: product?.sku?.toLowerCase() ?? ' ',
						item_name: product?.name?.toLowerCase() ?? ' ',
						coupon: ' ',
						discount:
							product?.prices?.base && product?.prices?.final
								? parseFloat(((product.prices.base - product.prices.final) * (qty ?? 1)).toFixed(2))
								: 0,
						item_brand: product?.attributes?.brand?.value?.toLowerCase() ?? ' ',
						item_category: product?.attributes?.category_level_1?.value?.toLowerCase() ?? ' ',
						item_list_name: listContext,
						item_list_id: listContext,
						index: 0,
						price: product?.prices?.final ? product.prices.final * (qty ?? 1) : ' ',
						quantity: qty ?? ' ',
						item_variant: selectedProduct?.sku?.toLowerCase() ?? ' ',
						availability: product?.inventory?.inStock ? 'in-stock' : 'out-of-stock',
						full_price: product?.prices?.base ? product.prices.base * (qty ?? 1) : ' ',
						bhCustomPageId: pageId,
					},
				],
			},
		},
	})
}
