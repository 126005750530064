import TagManager from '@sooro-io/react-gtm-module'
import { PRODUCT_TYPE_GROUPED } from '../../constants'
import { getContextValueBySku } from '../bh-list-context/utils/bh-tracking-context'

export const pageViewDetails = ({ pageType, params, pageContext, pageId, getLocalStorageByGroup }) => {
	switch (pageType) {
		case 'country-picker':
		case 'login':
		case 'register':
		case 'cms-page':
		case 'blog':
		case 'quiz':
		case 'store-locator':
		case 'store-locator-detail':
		case 'contacts':
		case 'workwithus':
		case 'not-found-page':
		case 'search':
		case 'master_data':
		case 'workwithus-advanced':
			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: pageType,
						returnCustomer: params?.isLoggedIn || false,
					},
				},
			})
			break

		case 'cart':
		case 'wishlist':
		case 'checkout':
			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: pageType,
						ecomm_prodid: params.items.map((item) => item.sku),
						ecomm_pvalue:
							pageType !== 'wishlist'
								? params.items.map((item) => item.totals.price)
								: params.items.map((item) => item.productData.prices.final),
						ecomm_pname: params.items.map((item) => item.name),
						...(pageType !== 'wishlist' ? { ecomm_totalvalue: params.totals ? params.totals.grandTotal : 0 } : null),
						returnCustomer: params?.isLoggedIn || false,
					},
				},
			})
			switch (pageType) {
				case 'cart':
				case 'pay-by-link':
					TagManager.dataLayer({ dataLayer: { ecommerce: null } })
					TagManager.dataLayer({
						dataLayer: {
							event: pageType === 'pay-by-link' ? 'payByLinkView' : 'cartView',
							ecommerce: {
								currency: params?.currency,
								value: params?.totals?.grandTotal,
								items: params.items.map(({ name, totals: { price }, sku, qty, productData }, index) => {
									const gtmEvent = pageType === 'pay-by-link' ? 'payByLinkView' : 'cartView'
									const listContext =
										getContextValueBySku({
											sku: productData?.sku || sku,
											pageContext,
											pageId,
											enablePageId: false,
											getLocalStorageByGroup,
										}) ?? gtmEvent
									return {
										item_id: productData?.sku || sku,
										item_name: name,
										currency: params?.currency,
										index: index + 1,
										item_brand: productData.attributes?.brand?.value || undefined,
										item_category: productData.attributes?.category_level_1?.value || '',
										item_list_id: listContext,
										item_list_name: listContext,
										price,
										quantity: qty,
										item_variant: '',
										bhCustomPageId: pageId,
									}
								}),
							},
						},
					})
					break
			}
			break

		case 'product':
			// eslint-disable-next-line no-case-declarations
			const products = [params, ...(params?.groupedChildrenProducts || [])]

			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: 'product',
						ecomm_prodid: products.map((product) => product.sku),
						ecomm_totalvalue: products.reduce((acc, product) => {
							return acc + (product?.type === PRODUCT_TYPE_GROUPED ? 0 : product?.prices?.final)
						}, 0),
						returnCustomer: params?.isLoggedIn || false,
					},
				},
			})
			TagManager.dataLayer({ dataLayer: { ecommerce: null } })
			TagManager.dataLayer({
				dataLayer: {
					event: 'productDetails',
					ecommerce: {
						detail: {
							actionField: {
								list:
									getContextValueBySku({
										sku: params?.sku,
										pageContext,
										pageId,
										enablePageId: false,
										getLocalStorageByGroup,
									}) ?? 'productDetails',
							},
							products: products.map((product) => ({
								name: product?.name,
								id: product?.sku,
								price: product?.prices?.final,
								category: product.attributes.category_level_1?.value || undefined,
								brand: product.attributes.brand?.value || undefined,
								bhCustomPageId: pageId,
							})),
						},
					},
				},
			})
			break

		case 'category':
			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: 'category',
						ecomm_category: params?.name,
						returnCustomer: params?.isLoggedIn || false,
					},
				},
			})
			break

		default:
			break
	}
}
