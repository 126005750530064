import TagManager from '@sooro-io/react-gtm-module'

export const pageView = ({ path, oldHistoryUrl, newHistoryUrl }) => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'pageview',
			page: {
				path,
				oldHistoryUrl,
				newHistoryUrl,
			},
		},
	})

	return true
}
