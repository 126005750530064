import TagManager from '@sooro-io/react-gtm-module'
import { getContextValueBySku } from '../bh-list-context/utils/bh-tracking-context'

export const addToCart = ({
	product,
	selectedProduct,
	cartItem,
	currency,
	qty,
	pageContext,
	pageId,
	widgetContext,
	getLocalStorageByGroup,
}) => {
	const gtmEvent = 'addToCart'
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			ecommerce: {
				currencyCode: currency,
				add: {
					actionField: {
						list:
							getContextValueBySku({
								sku: product?.sku,
								pageContext,
								pageId,
								enablePageId: false,
								widgetContext,
								getLocalStorageByGroup,
							}) ?? gtmEvent,
					},
					products: [
						{
							name: product?.name,
							id: product?.sku,
							// variant: "",
							price: cartItem?.totals?.price,
							brand: product?.attributes?.brand?.value || undefined,
							category: product?.attributes?.category_level_1?.value || undefined,
							quantity: qty,
							dimension20: selectedProduct?.sku || product?.sku,
							bhCustomPageId: pageId,
						},
					],
				},
			},
		},
	})
}
