import TagManager from '@sooro-io/react-gtm-module'

export const refundRequest = ({ orderNumber, orderProducts, returnProducts, isLoggedIn }) => {
	const productsOrderCount = orderProducts?.reduce((acc, item) => acc + item.qty, 0)
	const productsReturnCount = returnProducts?.reduce((acc, item) => acc + item.qty, 0)
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: isLoggedIn ? 'refundRequestCustomer' : 'refundRequestGuest',
			ecommerce: {
				refund: {
					actionField: {
						id: orderNumber,
					},
					...(productsReturnCount < productsOrderCount
						? {
								products: returnProducts.map((item) => {
									const itemInOrder = orderProducts.find((orderItem) => orderItem.id === item.orderItemId)
									return {
										id: itemInOrder.parentSku || itemInOrder.sku,
										quantity: item.qty,
									}
								}),
						  }
						: null),
				},
			},
		},
	})
}
