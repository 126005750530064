import TagManager from '@sooro-io/react-gtm-module'
import { cartItemsToGTMProducts } from './utils/products'

export const checkoutStepView = ({ stepCode, stepIndex, items, config, getLocalStorageByGroup }) => {
	const gtmEvent = 'checkout'
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			ecommerce: {
				checkout: {
					actionField: {
						step: stepIndex + 1,
						option: stepCode,
					},
					products: cartItemsToGTMProducts({ gtmEvent, items, config, getLocalStorageByGroup }),
				},
			},
		},
	})
}
