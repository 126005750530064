import TagManager from '@sooro-io/react-gtm-module'

export const refundRequest = ({ orderNumber, orderProducts, returnProducts, currency }) => {
	const products = orderProducts?.filter((item) => returnProducts?.some((item2) => item2.orderItemId === item.id))
	let value = 0
	let tax = 0

	products?.forEach((item) => {
		value += item.price * returnProducts?.find((item2) => item2.orderItemId === item.id).qty
		tax +=
			(item.totals.row_total_incl_tax / item.qty) * returnProducts?.find((item2) => item2.orderItemId === item.id).qty
	})

	TagManager.dataLayer({ dataLayer: { ecommerce: null } })

	TagManager.dataLayer({
		dataLayer: {
			event: 'refund',
			ecommerce: {
				ga: 'ga4',
				currency,
				value: value,
				tax: tax,
				shipping: 0,
				transaction_id: orderNumber,
				items: products.map((item, index) => ({
					item_id: item.sku?.toLowerCase() ?? ' ',
					item_name: item.name?.toLowerCase() ?? ' ',
					coupon: ' ',
					discount: ' ',
					item_brand: item.productData?.attributes?.brand?.value?.toLowerCase() ?? ' ',
					item_category: item.productData?.attributes?.category_level_1?.value?.toLowerCase() ?? ' ',
					item_list_name: item.productData?.attributes?.category_level_2?.value?.toLowerCase() ?? ' ',
					index: index,
					price: item?.productData?.prices?.final * item.qty ?? ' ',
					quantity: returnProducts?.find((item2) => item2.orderItemId === item.id).qty ?? ' ',
					item_variant: item.sku?.toLowerCase() ?? ' ',
					availability: item.productData?.inventory?.inStock ? 'in_stock' : 'out_of-stock',
					full_price:
						item.productData?.prices?.base * returnProducts?.find((item2) => item2.orderItemId === item.id).qty,
				})),
			},
		},
	})
}
