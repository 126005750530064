import TagManager from '@sooro-io/react-gtm-module'
import { getContextValueBySku } from '../bh-list-context/utils/bh-tracking-context'

export const removeFromWishlist = ({
	product,
	selectedProduct,
	currency,
	qty,
	/* configurableChildrenProducts, bundleProducts = [],*/ groupedChildrenProducts = [],
	pageContext,
	pageId,
	widgetContext,
	getLocalStorageByGroup,
}) => {
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })

	const gtmEvent = 'remove_from_wishlist'
	const listContext =
		getContextValueBySku({
			sku: product?.sku,
			pageContext,
			pageId,
			enablePageId: false,
			widgetContext,
			getLocalStorageByGroup,
		}) ?? gtmEvent
	const products = [product, ...groupedChildrenProducts]

	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			ecommerce: {
				currency,
				value:
					selectedProduct?.prices?.final ||
					products.reduce((acc, product) => {
						return acc + product?.prices?.final * (qty ?? 1)
					}, 0),
				items: products.map((product) => ({
					item_id: product?.sku?.toLowerCase() ?? ' ',
					item_name: product?.name?.toLowerCase() ?? ' ',
					coupon: ' ',
					discount:
						product?.prices?.base && product?.prices?.final
							? parseFloat(((product.prices.base - product.prices.final) * (qty ?? 1)).toFixed(2))
							: 0,
					item_brand: product?.attributes?.brand?.value?.toLowerCase() ?? ' ',
					item_category: product?.attributes?.category_level_1?.value?.toLowerCase() ?? ' ',
					item_list_name: listContext,
					item_list_id: listContext,
					index: 0,
					price: selectedProduct?.prices?.final || product?.prices?.final,
					quantity: qty ?? ' ',
					item_variant: selectedProduct?.sku?.toLowerCase() ?? ' ',
					availability: product?.inventory?.inStock ? 'in-stock' : 'out-of-stock',
					full_price: product?.prices?.base ? product.prices.base * (qty ?? 1) : ' ',
					bhCustomPageId: pageId,
				})),
			},
		},
	})
}
