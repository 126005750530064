import TagManager from '@sooro-io/react-gtm-module'

export const inlineSearch = ({ searchTerm }) => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'inlineSearch',
			searchTerm,
		},
	})
}
