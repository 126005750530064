import TagManager from '@sooro-io/react-gtm-module'

export const register = () => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'userRegistration',
			userRegistration: {
				registration_type: 'website',
			},
		},
	})
}
