import TagManager from '@sooro-io/react-gtm-module'

export const livstickAdded = ({ hasVideo = 'false' }) => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'add_gift_wrap',
			gift_wrap_attributes: {
				video: `${hasVideo}`,
			},
		},
	})

	return true
}

export const livstickRemoved = () => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'remove_gift_wrap',
		},
	})

	return true
}

export const livstickVideoSubmitted = () => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'gift_wrap_videosubmitted',
		},
	})

	return true
}
