import TagManager from '@sooro-io/react-gtm-module'

export const promotionClick = ({ id, name, creative, position }) => {
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: 'promotionClick',
			ecommerce: {
				promoClick: {
					promotions: [
						{
							id,
							name,
							creative,
							position,
						},
					],
				},
			},
		},
	})
}
