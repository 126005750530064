import TagManager from '@sooro-io/react-gtm-module'

export const menuItemClick = ({ entityId, entityType, entityName }) => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'menuItemClick',
			menuItem: {
				entityId,
				entityType,
				entityName,
			},
		},
	})
}
