import TagManager from '@sooro-io/react-gtm-module'

export const removeFromWishlist = ({
	product,
	selectedProduct,
	qty,
	/* configurableChildrenProducts, bundleProducts = [],*/ groupedChildrenProducts = [],
}) => {
	const products = [product, ...groupedChildrenProducts]
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: 'removeFromWishlist',
			removeFromWishlist: products.map((product) => ({
				product_id: product?.sku,
				product_name: product?.name,
				price: selectedProduct?.prices?.final || product?.prices?.final,
				brand: product?.attributes?.brand?.value || undefined,
				category: product?.attributes?.category_level_1?.value || undefined,
				// variant: "",
				quantity: qty,
				dimension20: selectedProduct?.sku || product?.sku,
			})),
		},
	})
}
