import TagManager from '@sooro-io/react-gtm-module'

export const hashChange = ({ hash }) => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'fragment',
			fragment: {
				fragment_value: hash,
			},
		},
	})
}
