import TagManager from '@sooro-io/react-gtm-module'
import { PRODUCT_TYPE_GROUPED } from '../../constants'
import { getContextValueBySku } from '../bh-list-context/utils/bh-tracking-context'
import { getLoginStatus, getSiteLanguage, getStoreCountry } from './utils/general'
import { cartItemsToGTM_GA4Products } from './utils/products'

export const pageViewDetails = ({ pageType, params, config, pageContext, pageId, getLocalStorageByGroup }) => {
	switch (pageType) {
		case 'checkout':
			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: pageType,
						ecomm_prodid: params.items.map(({ sku }) => sku),
						ecomm_pvalue: params.items.map(({ totals }) => totals.price),
						ecomm_pname: params.items.map(({ name }) => name),
						ecomm_totalvalue: params.totals?.grandTotal ?? 0,
						returnCustomer: !!params?.isLoggedIn,
					},
				},
			})
			TagManager.dataLayer({
				dataLayer: {
					login_status: getLoginStatus(params?.isLoggedIn),
					store_country: getStoreCountry(params?.locale),
					site_language: getSiteLanguage(params?.locale),
					page_type: pageType,
				},
			})
			TagManager.dataLayer({ dataLayer: { ecommerce: null } })

			TagManager.dataLayer({
				dataLayer: {
					event: 'begin_checkout',
					ecommerce: {
						currency: params?.currency,
						value: params?.totals?.grandTotal,
						items: cartItemsToGTM_GA4Products({
							gtmEvent: 'begin_checkout',
							items: params?.items,
							config,
							getLocalStorageByGroup,
						}),
						coupon: params?.totals?.couponCode,
					},
				},
			})
			break
		case 'cart':
			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: pageType,
						ecomm_prodid: params.items.map(({ sku }) => sku),
						ecomm_pvalue: params.items.map(({ totals }) => totals.price),
						ecomm_pname: params.items.map(({ name }) => name),
						ecomm_totalvalue: params.totals?.grandTotal ?? 0,
						returnCustomer: !!params?.isLoggedIn,
					},
				},
			})
			TagManager.dataLayer({
				dataLayer: {
					login_status: getLoginStatus(params?.isLoggedIn),
					store_country: getStoreCountry(params?.locale),
					site_language: getSiteLanguage(params?.locale),
					page_type: pageType,
				},
			})

			TagManager.dataLayer({ dataLayer: { ecommerce: null } })

			TagManager.dataLayer({
				dataLayer: {
					event: 'view_cart',
					ecommerce: {
						currency: params?.currency,
						value: params?.totals?.grandTotal,
						items: cartItemsToGTM_GA4Products({
							gtmEvent: 'view_cart',
							items: params?.items,
							config,
							getLocalStorageByGroup,
						}),
					},
				},
			})

			break
		case 'category':
			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: 'category',
						ecomm_category: params?.name,
						returnCustomer: !!params?.isLoggedIn,
					},
				},
			})
			TagManager.dataLayer({
				dataLayer: {
					login_status: getLoginStatus(params?.isLoggedIn),
					store_country: getStoreCountry(params?.locale),
					site_language: getSiteLanguage(params?.locale),
					page_type: pageType,
					page_section: params?.name,
				},
			})

			break
		case 'cms-page':
		case 'store-locator-detail':
		case 'master_data':
			TagManager.dataLayer({
				dataLayer: {
					login_status: getLoginStatus(params?.isLoggedIn),
					store_country: getStoreCountry(params?.locale),
					site_language: getSiteLanguage(params?.locale),
					page_type: pageType,
					page_section: params?.title,
				},
			})

			break
		case 'login':
		case 'register':
			TagManager.dataLayer({
				dataLayer: {
					login_status: 'guest',
					store_country: getStoreCountry(params?.locale),
					site_language: getSiteLanguage(params?.locale),
					page_type: pageType,
				},
			})

			break
		case 'product':
			// eslint-disable-next-line no-case-declarations
			const products = [params, ...(params?.groupedChildrenProducts || [])]

			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: 'product',
						ecomm_prodid: products.map((product) => product.sku),
						ecomm_totalvalue: products.reduce((acc, product) => {
							return acc + (product?.type === PRODUCT_TYPE_GROUPED ? 0 : product?.prices?.final)
						}, 0),
						returnCustomer: !!params?.isLoggedIn,
					},
				},
			})
			TagManager.dataLayer({
				dataLayer: {
					login_status: getLoginStatus(params?.isLoggedIn),
					store_country: getStoreCountry(params?.locale),
					site_language: getSiteLanguage(params?.locale),
					page_type: pageType,
					page_section: params?.sku,
				},
			})

			TagManager.dataLayer({ dataLayer: { ecommerce: null } })

			TagManager.dataLayer({
				dataLayer: {
					event: 'view_item',
					ecommerce: {
						currency: params?.currency,
						value: products.reduce((acc, product) => {
							return acc + product?.prices?.final
						}, 0),
						items: products.map((product) => {
							const gtmEvent = 'view_item'
							const listContext =
								getContextValueBySku({
									sku: params?.sku,
									pageContext,
									pageId,
									enablePageId: false,
									getLocalStorageByGroup,
								}) ?? gtmEvent

							return {
								item_id: product.sku,
								item_name: product.name,
								coupon: ' ',
								discount:
									product?.prices?.base && product?.prices?.final
										? parseFloat((product.prices.base - product.prices.final).toFixed(2))
										: 0,
								item_brand: product?.attributes?.brand?.value,
								item_category: product?.attributes?.category_level_1?.value,
								item_list_name: listContext,
								item_list_id: listContext,
								index: 0,
								price: product.prices?.final,
								quantity: product.minQty,
								item_variant: ' ',
								availability: product?.inventory?.inStock ? 'in_stock' : 'out_of-stock',
								full_price: product?.prices?.base,
								bhCustomPageId: pageId,
							}
						}),
					},
				},
			})

			break
		case 'wishlist':
			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: pageType,
						ecomm_prodid: params.items.map(({ sku }) => sku),
						ecomm_pvalue: params.items.map((item) => item.productData.prices.final),
						ecomm_pname: params.items.map(({ name }) => name),
						returnCustomer: !!params?.isLoggedIn,
					},
				},
			})
			TagManager.dataLayer({
				dataLayer: {
					login_status: getLoginStatus(params?.isLoggedIn),
					store_country: getStoreCountry(params?.locale),
					site_language: getSiteLanguage(params?.locale),
					page_type: pageType,
				},
			})
			break
		default:
			TagManager.dataLayer({
				dataLayer: {
					event: 'fireRemarketingTag',
					google_tag_params: {
						ecomm_pagetype: pageType,
						returnCustomer: !!params?.isLoggedIn,
					},
				},
			})
			TagManager.dataLayer({
				dataLayer: {
					login_status: getLoginStatus(params?.isLoggedIn),
					store_country: getStoreCountry(params?.locale),
					site_language: getSiteLanguage(params?.locale),
					page_type: pageType,
				},
			})

			break
	}
}
