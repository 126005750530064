import TagManager from '@sooro-io/react-gtm-module'

export const checkoutSetPaymentMethod = ({ stepIndex, paymentMethod }) => {
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: 'checkoutOption',
			ecommerce: {
				checkout_option: {
					actionField: {
						step: stepIndex + 1,
						option: paymentMethod,
					},
				},
			},
		},
	})
}
