import TagManager from '@sooro-io/react-gtm-module'
import { isFullURL } from '@bluheadless/ui-logic/src/utils'

export const init = ({ code, storeCode, currencyCode, originalLocation, config: { trackings } }) => {
	if (!window.google_tag_manager?.[code]) {
		const file_url = trackings.find((tracking) => tracking.id === 'gtm-ga4').file_url
		TagManager.initialize({
			gtmId: code,
			auth: trackings.find((tracking) => tracking.id === 'gtm-ga4').auth,
			preview: trackings.find((tracking) => tracking.id === 'gtm-ga4').preview,
			dataLayer: {
				originalLocation,
				store: storeCode,
				currencyCode,
			},
			source: isFullURL(file_url) ? file_url : undefined,
		})
	}

	return true
}
