import TagManager from '@sooro-io/react-gtm-module'
import { getContextValueBySku } from '../bh-list-context/utils/bh-tracking-context'

export const addToWishlist = ({
	product,
	selectedProduct,
	qty,
	currency,
	/* configurableChildrenProducts, bundleProducts = [], */ groupedChildrenProducts = [],
	pageContext,
	pageId,
	widgetContext,
	getLocalStorageByGroup,
}) => {
	const gtmEvent = 'addToWishlist'
	const listContext =
		getContextValueBySku({
			sku: product?.sku,
			pageContext,
			pageId,
			enablePageId: false,
			widgetContext,
			getLocalStorageByGroup,
		}) ?? gtmEvent
	/*const { configurableOptions = [] } = product*/
	const products = [product, ...groupedChildrenProducts]
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			ecommerce: {
				currency: currency,
				value:
					selectedProduct?.prices?.final * (qty ?? 1) ||
					products.reduce((acc, product) => {
						return acc + product?.prices?.final * (qty ?? 1)
					}, 0),
				items: products.map((product) => ({
					item_id: product?.sku,
					item_name: product?.name,
					currency: currency,
					item_brand: product?.attributes?.brand?.value || undefined,
					item_category: product?.attributes?.category_level_1?.value || undefined,
					item_list_id: listContext,
					item_list_name: listContext,
					price: selectedProduct?.prices?.final || product?.prices?.final,
					quantity: qty,
					item_variant: '',
					bhCustomPageId: pageId,
					/*discount: parseFloat(
						parseFloat(
							(selectedProduct?.prices?.base || product?.prices?.base) -
								(selectedProduct?.prices?.final || product?.prices?.final)
						).toFixed(2)
					),*/
					/*...(configurableOptions.length > 0 && selectedProduct
						? configurableOptions.reduce(
								(acc, { attributeCode }) => ({
									...acc,
									['item_variant_' + attributeCode]: selectedProduct.attributes[attributeCode].value,
								}),
								{}
						  )
						: {}),*/
				})),
			},
			/*addToWishlist: {
				product_id: product?.sku,
				product_name: product?.name,
				price: selectedProduct?.prices?.final || product?.prices?.final,
				brand: product?.attributes?.brand?.value || undefined,
				category: product?.attributes?.category_level_1?.value || undefined,
				// variant: "",
				quantity: qty,
				dimension20: selectedProduct?.sku || product?.sku,
			},*/
		},
	})
}
