import TagManager from '@sooro-io/react-gtm-module'

export const coreWebVitals = ({ id, label, name, startTime, value }) => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'coreWebVitals',
			event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
			webVitalsMeasurement: {
				name,
				id,
				value,
				valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
				startTime,
			},
		},
	})
}
