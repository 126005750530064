import TagManager from '@sooro-io/react-gtm-module'
import { cartItemsToGTMProducts } from './utils/products'

export const checkoutPurchase = ({ currency, order, config, getLocalStorageByGroup, email }) => {
	const gtmEvent = 'purchase'
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			email,
			ecommerce: {
				currencyCode: currency,
				purchase: {
					actionField: {
						id: order.number,
						affiliation: 'Online Store',
						revenue: order.totals.grandTotal,
						tax: order.totals.tax,
						shipping: order.totals.shipping,
						coupon: order.coupon ?? undefined,
					},
					products: cartItemsToGTMProducts({ gtmEvent, items: order.items, config, getLocalStorageByGroup }),
				},
			},
		},
	})
}
