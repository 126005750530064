import TagManager from '@sooro-io/react-gtm-module'
import { getContextValue } from '../bh-list-context/utils/bh-tracking-context'

export const productClick = ({
	params: { product, position, /* configurableChildrenProducts, bundleProducts = [],*/ groupedChildrenProducts = [] },
	pageContext,
	pageId,
	widgetContext,
}) => {
	const products = [product, ...groupedChildrenProducts]
	const gtmEvent = 'productClick'
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			ecommerce: {
				click: {
					actionField: {
						list: getContextValue({ pageContext, pageId, enablePageId: false, widgetContext }) ?? gtmEvent,
					},
					products: products.map((product) => ({
						name: product?.name,
						id: product?.sku,
						price: product?.prices?.final,
						category: product.attributes?.category_level_1?.value || undefined,
						brand: product.attributes?.brand?.value || undefined,
						// variant: "",
						position: position,
						dimension20: undefined,
						bhCustomPageId: pageId,
					})),
				},
			},
		},
	})
	return true
}
