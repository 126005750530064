import TagManager from '@sooro-io/react-gtm-module'
import { cartItemsToGTM_GA4Products } from './utils/products'

export const checkoutPurchase = ({ currency, order, config, getLocalStorageByGroup, email }) => {
	const gtmEvent = 'purchase'
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			email,
			ecommerce: {
				transaction_id: order.number,
				currency,
				value: order.totals.grandTotal,
				tax: order.totals.tax,
				shipping: order.totals.shipping,
				coupon: order.coupon ?? undefined,
				items: cartItemsToGTM_GA4Products({ gtmEvent, items: order.items, config, getLocalStorageByGroup }),
			},
		},
	})
}
