import TagManager from '@sooro-io/react-gtm-module'
import { getContextValueBySku } from '../bh-list-context/utils/bh-tracking-context'

export const removeFromCart = ({
	product,
	selectedProduct,
	cartItem,
	qty,
	pageContext,
	pageId,
	widgetContext,
	getLocalStorageByGroup,
}) => {
	const gtmEvent = 'removeFromCart'
	const listContext =
		getContextValueBySku({
			sku: product?.sku,
			pageContext,
			pageId,
			enablePageId: false,
			widgetContext,
			getLocalStorageByGroup,
		}) ?? gtmEvent
	// product.id is cartItem Id
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			ecommerce: {
				remove: {
					actionField: {
						list: listContext,
					},
					products: [
						{
							name: product?.name,
							id: product?.sku,
							price: cartItem?.totals?.price,
							brand: product?.attributes?.brand?.value || undefined,
							category: product?.attributes?.category_level_1?.value || undefined,
							list: listContext,
							// variant: "",
							quantity: qty,
							dimension20: selectedProduct?.sku || product?.sku,
							bhCustomPageId: pageId,
						},
					],
				},
			},
		},
	})
}
