import TagManager from '@sooro-io/react-gtm-module'
import { getContextValue } from '../bh-list-context/utils/bh-tracking-context'

export const productClick = ({
	params: {
		product,
		position,
		currency,
		/* configurableChildrenProducts, bundleProducts = [],*/ groupedChildrenProducts = [],
	},
	pageContext,
	pageId,
	widgetContext,
}) => {
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })

	const products = [product, ...groupedChildrenProducts]
	const gtmEvent = 'select_item'

	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			ecommerce: {
				currency,
				items: products.map((product) => {
					const listContext = getContextValue({ pageContext, pageId, enablePageId: false, widgetContext }) ?? gtmEvent
					return {
						item_id: product?.sku?.toLowerCase() ?? ' ',
						item_name: product?.name?.toLowerCase() ?? ' ',
						coupon: ' ',
						discount:
							product?.prices?.base && product?.prices?.final
								? parseFloat((product.prices.base - product.prices.final).toFixed(2))
								: 0,
						item_brand: product?.attributes?.brand?.value?.toLowerCase() ?? ' ',
						item_category: product?.attributes?.category_level_1?.value?.toLowerCase() ?? ' ',
						item_list_name: listContext,
						item_list_id: listContext,
						index: position ?? 0,
						price: product?.prices?.final ?? ' ',
						quantity: product?.inventory?.minQty ?? ' ',
						item_variant: ' ',
						availability: product?.inventory?.inStock ? 'in-stock' : 'out-of-stock',
						full_price: product?.prices?.base ?? ' ',
						bhCustomPageId: pageId,
					}
				}),
			},
		},
	})
}
