import TagManager from '@sooro-io/react-gtm-module'
import { CHECKOUT_STEP_PAYMENT, CHECKOUT_STEP_SHIPPING } from '../../checkout/steps'
import { cartItemsToGTM_GA4Products } from './utils/products'

export const checkoutStepCompleted = ({
	stepCode,
	currency,
	totals: { grandTotal },
	paymentMethod,
	items,
	shippingMethod,
	shippingMethodTitle,
	couponCode,
	config,
	getLocalStorageByGroup,
}) => {
	switch (stepCode) {
		case CHECKOUT_STEP_SHIPPING:
			TagManager.dataLayer({ dataLayer: { ecommerce: null } })
			TagManager.dataLayer({
				dataLayer: {
					event: 'add_shipping_info',
					ecommerce: {
						currency,
						coupon: couponCode,
						value: grandTotal,
						shipping_tier: shippingMethodTitle ?? shippingMethod,
						items: cartItemsToGTM_GA4Products({
							gtmEvent: 'add_shipping_info',
							items,
							config,
							getLocalStorageByGroup,
						}),
					},
				},
			})
			break
		case CHECKOUT_STEP_PAYMENT:
			TagManager.dataLayer({ dataLayer: { ecommerce: null } })
			TagManager.dataLayer({
				dataLayer: {
					event: 'add_payment_info',
					ecommerce: {
						currency,
						coupon: couponCode,
						value: grandTotal,
						payment_type: paymentMethod,
						items: cartItemsToGTM_GA4Products({
							gtmEvent: 'add_payment_info',
							items,
							config,
							getLocalStorageByGroup,
						}),
					},
				},
			})
			break
	}
}
