import TagManager from '@sooro-io/react-gtm-module'

export const customerInfo = ({ customer, subscribed }) => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'customerinfo',
			customerLoggedId: customer.id,
			email: customer.email,
			subscribed,
		},
	})
}
