import TagManager from '@sooro-io/react-gtm-module'
import { getContextValue } from '../bh-list-context/utils/bh-tracking-context'

export const productImpressions = ({ products, currency, pageContext, pageId, widgetContext }) => {
	if (products.length) {
		const gtmEvent = 'productImpressions'
		TagManager.dataLayer({ dataLayer: { ecommerce: null } })
		TagManager.dataLayer({
			dataLayer: {
				event: gtmEvent,
				ecommerce: {
					currencyCode: currency,
					impressions: products.map((product, index) => ({
						id: product?.sku,
						position: index + 1,
						name: product?.name,
						price: product?.prices?.final,
						category: product?.attributes?.category_level_1?.value || undefined,
						list: getContextValue({ pageContext, pageId, enablePageId: false, widgetContext }) ?? gtmEvent,
						// variant: '',
						brand: product.attributes.brand?.value || undefined,
						dimension20: undefined,
						bhCustomPageId: pageId,
					})),
				},
			},
		})
	}

	return true
}
