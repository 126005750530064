import TagManager from '@sooro-io/react-gtm-module'

export const clickMenuImage = ({ id, name, creative }) => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'click_menu_image',
			ecommerce: {
				clickMenuImage: {
					id,
					name,
					creative,
				},
			},
		},
	})
}
