import TagManager from '@sooro-io/react-gtm-module'

export const login = () => {
	TagManager.dataLayer({
		dataLayer: {
			event: 'userLogin',
			userLogin: {
				login_type: 'website',
			},
		},
	})
}
