import TagManager from '@sooro-io/react-gtm-module'

export const promotionClick = ({ id, name, creative }) => {
	TagManager.dataLayer({ dataLayer: { ecommerce: null } })

	TagManager.dataLayer({
		dataLayer: {
			event: 'select_promotion',
			ecommerce: {
				creative_name: creative,
				promotion_id: id,
				promotion_name: name,
			},
		},
	})
}
