import TagManager from '@sooro-io/react-gtm-module'
import { getContextValue } from '../bh-list-context/utils/bh-tracking-context'

export const productImpressions = ({ currency, products, pageContext, pageId, widgetContext }) => {
	const gtmEvent = 'view_item_list'

	TagManager.dataLayer({ dataLayer: { ecommerce: null } })
	TagManager.dataLayer({
		dataLayer: {
			event: gtmEvent,
			ecommerce: {
				currency,
				items: products?.map((product, index) => {
					const listContext = getContextValue({ pageContext, pageId, enablePageId: false, widgetContext }) ?? gtmEvent
					return {
						item_id: product.sku?.toLowerCase() ?? ' ',
						item_name: product.name?.toLowerCase() ?? ' ',
						coupon: ' ',
						discount:
							product.prices?.base && product.prices?.final
								? parseFloat((product.prices.base - product.prices.final).toFixed(2))
								: 0,
						item_brand: product.attributes?.brand?.value?.toLowerCase() ?? ' ',
						item_category: product.attributes?.category_level_1?.value?.toLowerCase() ?? ' ',
						item_list_name: listContext,
						item_list_id: listContext,
						index: index ?? 0,
						price: product.prices?.final ? parseFloat(product.prices.final.toFixed(2)) : ' ',
						quantity: product.inventory?.minQty ?? ' ',
						item_variant: ' ',
						availability: product.inventory?.inStock ? 'in-stock' : 'out-of-stock',
						full_price: product.prices?.base ? parseFloat(product.prices.base.toFixed(2)) : ' ',
						bhCustomPageId: pageId,
					}
				}),
			},
		},
	})
}
